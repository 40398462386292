<template>
  <div id="login">
    <div class="container">
      <article class="message is-info" :class="{'message-invis': !msgDisplay}">
        <div class="message-header">
          <p>Info</p>
          <button class="delete" aria-label="delete" @click="msgDisplay=false"></button>
        </div>
        <div class="message-body">
          You are not logged in or your session has expired. Please log in to continue.
        </div>
      </article>
      <div class="panel-head">
        Login
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-left has-icons-right">
          <input class="input" :class="{'is-success': false}" type="email" placeholder="me@here.com" v-model="user.username">
          <span class="icon is-small is-left">
            <i class="fas fa-user"></i>
          </span>
          <!-- <span class="icon is-small is-right">
            <i class="fas fa-check"></i>
          </span> -->
        </div>
        <!-- <p class="help is-success">This username is available</p> -->
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left has-icons-right">
          <input class="input" :class="{'is-success': false}" type="password" placeholder="password" v-model="user.password">
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
          <!-- <span class="icon is-small is-right">
            <i class="fas fa-check"></i>
          </span> -->
        </div>
        <!-- <p class="help is-success">This username is available</p> -->
      </div>
      <div class="form-end">
        <button class="button" @click.prevent="login">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data () {
    return {
      user: {
        username: '',
        password: ''
      },
      msgDisplay: this.$route.query.redirect
    }
  },
  methods: {
    login() {
      const vm = this;
      this.axios.post('/auth', this.user)
      .then(function (response) {
        // console.log(response.data);
        window.localStorage.setItem('constack', JSON.stringify(response.data));
        vm.$store.commit('setUser', response.data);
        if (vm.$route.query.redirect) {
          vm.$router.push(vm.$route.query.redirect);
          // vm.$router.push('/');
        } else {
          if (vm.$route.path !== "/") {
            vm.$router.push('/');
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

.container {
  display: flex;
  flex-direction: column;
  width: 50%;
  border: 1px solid #272727;
  border-radius: 4px;
  position: relative;
}

.panel-head {
  padding: 1rem;
  background-color: var(--bg-gray);
  color: white;
  font-size: 1.2em;
  font-weight: 700;
}

.field {
  padding-left: 1rem;
  padding-right: 1rem;
}
.field:first-of-type {
  padding-top: 1rem;
}
.field:last-of-type {
  padding-bottom: 1rem;
}

.form-end {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-around;
}

.message {
  position: fixed;
  bottom: 60px;
  transition: 1s ease-in-out;
}

.message-invis {
  bottom: -100%;
}

</style>
